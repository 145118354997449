module app.controllers.applicationcore {
    export class changePasswordCtrl {

        static $inject = ['$scope', 'userAccountService', '$rootScope', 'generalService', 'bsLoadingOverlayService', '$state', '$stateParams'];

        password: { password: string, confirmPassword: string} = {
            password: "",
            confirmPassword: ""
        }

        constructor(private $scope: ng.IScope,
            private userAccountService: interfaces.applicationcore.IUserAccountService,
            private $rootScope: interfaces.applicationcore.IRootScope,
            public generalService: interfaces.applicationcore.IGeneralService,
            private bsLoadingOverlayService,
            public $state: ng.ui.IStateService,
        public $stateParams: ng.ui.IStateParamsService) {

            this.$rootScope.isLoggedIn = false;
        }

        submitForm() {
            if (this.password.password && this.password.confirmPassword && this.password.password == this.password.confirmPassword) {

                if(this.$stateParams["resetToken"]) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: "pass.change"
                    }, () => {
                        return this.userAccountService.changePassword(this.password.password, this.password.confirmPassword, this.$stateParams["resetToken"]).save(this.password, (result) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>result);
                            this.$state.go("login");
                        }, (reponseError) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>reponseError.data);
                        }).$promise;
                    });
                } else {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: "pass.change"
                    }, () => {
                        return this.userAccountService.changePassword(this.password.password, this.password.confirmPassword).save(this.password, (result) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>result);
                            this.$state.go("login");
                        }, (reponseError) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>reponseError.data);
                        }).$promise;
                    });
                }
            }
        }
    };

    angular.module("app").controller("changePasswordCtrl", controllers.applicationcore.changePasswordCtrl);
}